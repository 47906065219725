import React from 'react'
import "../styles/testimonial.scss"
import {GoQuote} from "react-icons/go"
import {AiFillStar} from "react-icons/ai"
import { TestimonialData } from '../json/JsonData'
import { motion } from 'framer-motion'

const Testimonial = () => {

  return (
    <div id='testimonial'>
      <h2>Hear from our Customers..!!</h2>
      <div className='cards'>
        {TestimonialData.map((data) => <TestimonialCard key={data.id} id={data.id} name={data.name} rating={data.rating} message={data.message} />)}
      </div>
    </div>
  )
}

const TestimonialCard = ({id, name, rating, message}) => {

    const stars = []

    for(let i=0; i<rating; i++){
        stars.push(i)
    }

    return(
        <motion.div initial={{y:100, opacity:0}} whileInView={{y:0, opacity:1}} whileHover={{y:-3}} transition={{duration:0.5}} id={id} className='card'>
            <h4>{name}</h4>
            <div className='stars'>
                {stars.map((i, index) => <AiFillStar key={index} />)}
            </div>
            <GoQuote />
            <p>{message}</p>
        </motion.div>
        
    )
}

export default Testimonial
