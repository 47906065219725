import React from 'react'
import "../styles/contactus.scss"
import Map from './Map'

class ContactUs extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      message: '',
      formErrors:{phone:""},
      phoneValid: false,
      formValid: false
    }
  }

    validateForm = () => {
      this.setState({formValid: this.state.phoneValid})
    }

    validateField = (fieldName, value) => {
      let fieldValidationErrors = this.state.formErrors;
      let phoneValid = this.state.phoneValid;
    
      switch(fieldName) {
        case 'phone':
          phoneValid = value.match(/(6|7|8|9)\d{9}/);
          fieldValidationErrors.phone = phoneValid ? '' : ' is invalid: Enter 10 digit number starting from either 6, 7, 8 or 9';
          break;
        default:
          break;
      }
      this.setState({formErrors:fieldValidationErrors, phoneValid: phoneValid}, this.validateForm)
    }

    changeHandler = (e) =>{
      this.setState({[e.target.name]:e.target.value}, () => this.validateField(e.target.name, e.target.value))
    }
    render(){
  return (
    <div id="contactus">
        <div className='wrapper'>
        <form action="https://formspree.io/f/mzbqkalq" method="POST">
        <h2>If you have any questions feel free to contact us!</h2>
        <aside>
            <div>
            <input type="text" name="name" autoComplete='off' placeholder='Name' value={this.state.name} onChange={this.changeHandler} id="name" required />
            </div>
            <div>
            {/* pattern="/(6|7|8|9)\d{9}/" */}
            <input type="text" name="phone" autoComplete='off' placeholder='Phone' value={this.state.phone} onChange={this.changeHandler} id="phone" required />
            </div>
        </aside>
        <input type="email" name="email" autoComplete='off' placeholder='Email' value={this.state.email} onChange={this.changeHandler} id="email" required />
        <textarea type="text" name="message" autoComplete='off' rows={5} placeholder='Message...' value={this.state.message} onChange={this.changeHandler} id="message" required />
        <FormErrors formErrors={this.state.formErrors} />
        <button type="submit" disabled={!this.state.formValid} >Send Message</button>
      </form>
      <div>
        <Map />
      </div>
      </div>
    </div>
  )}
}

const FormErrors = ({formErrors}) =>
  <div className='formErrors'>
    {Object.keys(formErrors).map((fieldName, i) => {
      if(formErrors[fieldName].length > 0){
        return (
          <p key={i}>{fieldName} {formErrors[fieldName]}</p>
        )        
      } else {
        return '';
      }
    })}
  </div>

export default ContactUs
