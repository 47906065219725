import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "../styles/hero.scss"
import img1 from "../assets/img1.jpg"
import img2 from "../assets/img2.jpg"
import img3 from "../assets/img3.jpg"
import img4 from "../assets/img4.jpg"
import img5 from "../assets/img5.jpg"
import { motion } from 'framer-motion'

const Hero = () => {

  const [offsetY, setOffsetY] = useState(0)
  const handleScroll = () => setOffsetY(window.pageYOffset)

  useEffect(()=>{
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className='hero'>
      <section style={{transform: `translateY(${offsetY * 0.5}px)`}}>
      <Carousel className='carousel' showArrows={false} interval={5000} showIndicators={false} showStatus={false} showThumbs={false} infiniteLoop={true} dynamicHeight={false} autoPlay={true}>
          <img src={img1} className="imgs" alt="img1" />
          <img src={img2} className="imgs" alt="img2" />
          <img src={img3} className="imgs" alt="img3" />
          <img src={img4} className="imgs" alt="img4" />
          <img src={img5} className="imgs" alt="img5" />
      </Carousel>
        <main>
          <motion.h2 initial={{x:-10, opacity:0}} whileInView={{x:0, opacity:1}} transition={{delay:0.3, duration:0.5}}>Are you dreaming of a place to stay? We are here!</motion.h2>
          <motion.p initial={{x:10, opacity:0}} whileInView={{x:0, opacity:1}} transition={{delay:0.3, duration:0.5}}>Check out our interesting offers just for you</motion.p>
          {/* <p>Book Now</p> */}
          <div>
            {/* <a href="https://www.booking.com/hotel/in/anandvan-beach-resort.html" target="blank">Booking.com</a>
            <a href="https://www.agoda.com/anandvan-beach-resort/hotel/alibaug-in.html?cid=1844104" target="blank">Agoda</a> */}
            <motion.a href="https://www.makemytrip.com/hotels/hotel-details?hotelId=202212131758139030&checkin=date_3&checkout=date_4&country=IN&city=CTXGH&roomStayQualifier=2e0e&openDetail=true&currency=ENG&region=IN&checkAvailability=true&locusId=CTXGH&locusType=city&homestay=true&zcp=154ba927a752" whileHover={{ scale: 1.1 }} target="blank">Book Now</motion.a>
          </div>
        </main>
      </section>
    </div>
  )
}

export default Hero
