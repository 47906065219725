import React from 'react'
import icon from "../assets/icon.png"
import {GiHamburgerMenu} from "react-icons/gi"
import {MdLocationOn, MdEmail } from "react-icons/md"
import { BsFacebook} from "react-icons/bs"
import {AiFillInstagram} from "react-icons/ai"
import {RiWhatsappFill} from "react-icons/ri"
import "../styles/navbar.scss"
import {motion, AnimatePresence} from "framer-motion"

function Navbar({isOpen, setIsOpen}) {
  return (
    <>
    <motion.div
    initial={{y:"-100vh", opacity:0}}
    animate={{y:"0", opacity:1}}
    transition={{duration:0.3, type:"just", delay:0.3}}
     className='navBar'>
      <div>
        <motion.a whileHover={{scale:1.3}} href='https://maps.app.goo.gl/pUu9yn1NMG112Do99' target="blank"><MdLocationOn /></motion.a>
        <motion.a whileHover={{scale:1.3}} href='mailto:anandvanbeachresort@gmail.com' target="blank"><MdEmail /></motion.a>
        <motion.a whileHover={{scale:1.3}} href='https://www.facebook.com/profile.php?id=100087098630049&mibextid=ZbWKwL' target="blank"><BsFacebook /></motion.a>
        <motion.a whileHover={{scale:1.3}} href='https://instagram.com/anandvanbeachresort?igshid=ZDdkNTZiNTM=' target="blank"><AiFillInstagram /></motion.a>
        <motion.a whileHover={{scale:1.3}} href='https://wa.me/message/3KFOG3ZBTAYIG1' target="blank"><RiWhatsappFill /></motion.a>
      </div>
      <img src={icon} alt="icon" />
      <aside className={`${isOpen?"burgerwhite":""}`} onClick={() => setIsOpen(!isOpen)}>
        <GiHamburgerMenu />
        <p>Menu</p>
      </aside>
    </motion.div>
    </>
  )
}

const asideVarients={
  hidden:{
    opacity:0,
    x:"100vw"
  },
  visible:{
    opacity:1,
    x:0,
    transition: {type:"just", stiffness:40, duration:0.5}
  },
  exit:{
    x:"100vw",
    transition: {ease:"easeInOut", duration:0.5}
  }
}

export const NavbarAside = ({isOpen, setIsOpen}) =>{
  return(
    <AnimatePresence>
    {isOpen && <motion.div 
    variants={asideVarients} initial="hidden" animate="visible" exit="exit" 
    className="navbaraside">
      <main >
        <motion.a whileHover={{scale:1.3, originX:0}} href="#aboutus" onClick={() => setIsOpen(false)} >About Us</motion.a>
        <motion.a whileHover={{scale:1.3, originX:0}} href="#roomsandsuits" onClick={() => setIsOpen(false)} >Rooms & Suits</motion.a>
        <motion.a whileHover={{scale:1.3, originX:0}} href="#gallery" onClick={() => setIsOpen(false)} >Gallery</motion.a>
        <motion.a whileHover={{scale:1.3, originX:0}} href="#services" onClick={() => setIsOpen(false)} >Services</motion.a>
        <motion.a whileHover={{scale:1.3, originX:0}} href="#amenities" onClick={() => setIsOpen(false)} >Amenities</motion.a>
        <motion.a whileHover={{scale:1.3, originX:0}} href="#places" onClick={() => setIsOpen(false)}>Places</motion.a>
        <motion.a whileHover={{scale:1.3, originX:0}} href="#testimonial" onClick={() => setIsOpen(false)}>Testimonial</motion.a>
        <motion.a whileHover={{scale:1.3, originX:0}} href="#contactus" onClick={() => setIsOpen(false)} >Contact Us</motion.a>
      </main>
      <div>
        <a href='https://maps.app.goo.gl/pUu9yn1NMG112Do99' target="blank"><MdLocationOn /></a>
        <a href='mailto:anandvanbeachresort@gmail.com' target="blank"><MdEmail /></a>
        <a href='https://www.facebook.com/profile.php?id=100087098630049&mibextid=ZbWKwL' target="blank"><BsFacebook /></a>
        <a href='https://instagram.com/anandvanbeachresort?igshid=ZDdkNTZiNTM=' target="blank"><AiFillInstagram /></a>
        <a href='https://wa.me/message/3KFOG3ZBTAYIG1' target="blank"><RiWhatsappFill /></a>
      </div>
    </motion.div>}
    </AnimatePresence>
  )
}
 
export default Navbar
