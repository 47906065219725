import "./styles/app.scss";
import "./styles/media.scss";
import React, { useState } from 'react'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Hero from "./components/Hero";
import Navbar, { NavbarAside } from "./components/Navbar";
import AboutUs from "./components/AboutUs";
import RoomsSuits from "./components/RoomsSuits";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Testimonial from "./components/Testimonial";
import Places from "./components/Places";
import Amenities from "./components/Amenities";
import Services from "./components/Services";
import {FaWhatsappSquare} from "react-icons/fa"
import { MdCall, MdEmail } from "react-icons/md";
import { motion } from "framer-motion";
import {BsHeadset} from "react-icons/bs"

const contactModalVarients={
  hidden:{
    opacity:0,
    x:10,
    y:10
  },
  visible:{
    opacity:1,
    x:0,
    y:0,
    transition: {duration:0.5}
  },
  exit:{
    opacity:0,
    x:10,
    y:10,
    transition: {ease:"easeInOut", duration:0.5}
  }
}

function App() {

  const [isOpen, setIsOpen] = useState(false)
  const [isContactOpen, setIsContactOpen] = useState(false)

  return (
    <BrowserRouter>
    <NavbarAside isOpen={isOpen} setIsOpen={setIsOpen} />
    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
    {isContactOpen && 
      <motion.div variants={contactModalVarients} initial="hidden" animate="visible" exit="exit" id="contactmodal">
        <a href="tel:9769305050"><MdCall /><p>+91 9769305050</p></a>
        <a href="mailto:anandvanbeachresort@gmail.com"><MdEmail /><p>anandvanbeachresort@gmail.com</p></a>
      </motion.div>
    }
    <div id="contact">
      <span><BsHeadset onClick={()=>setIsContactOpen(!isContactOpen)} /></span>
    </div>
    <div id="whatsapp">
      <a href='https://wa.me/message/3KFOG3ZBTAYIG1' target="blank"><FaWhatsappSquare /></a>
    </div>
      <Routes>
        <Route exact path="/" element={ <>
          <Hero isOpen={isOpen} setIsOpen={setIsOpen} />
          <AboutUs />
          <RoomsSuits />
          <Gallery />
          <Services />
          <Amenities />
          <Places />
          <Testimonial />
          <ContactUs />
          <Footer />
        </>
      } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
