import React from 'react'
import "../styles/footer.scss"
import icon from "../assets/icon.png"
import { MdEmail, MdLocationOn } from 'react-icons/md'
import { BsFacebook } from 'react-icons/bs'
import { RiWhatsappFill } from 'react-icons/ri'
import { AiFillInstagram, AiFillPhone } from 'react-icons/ai'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='icondetail'>
        <img src={icon} alt="icon" />
        <p>Anandvan is ready to truly lock the feel of a rural village stretching along with the beach and meeting Arabian sea waves. Located conveniently near Mumbai and Pune with clean water and excellent accommodation with a perfect blend of a romantic getaway, family outings, birthday events, weddings, corporate event gatherings and much more within the warmth of personal hospitality to give you an experience that you never had before.</p>
      </div>
      <div className='linkscontacts'>
      <div className='links'>
        <h2>Links</h2>
        <a href="#aboutus" >About Us</a>
        <a href="#roomsandsuits" >Rooms & Suits</a>
        <a href="#gallery" >Gallery</a>
        <a href="#services" >Services</a>
        <a href="#amenities" >Amenities</a>
        <a href="#places" >Places</a>
        <a href="#testimonial" >Testimonial</a>
        <a href="#contactus" >Contact Us</a>
      </div>
      <div className='contacts'>
        <h2>Contacts</h2>
        <a href='https://maps.app.goo.gl/pUu9yn1NMG112Do99' target="blank">
            <MdLocationOn className='location' />
            <p>Anandvan Beach Resort, Sonarpeth, Near Vitthal Mandir, Nagaon, Alibag – 402204</p>
        </a>
        <a href='mailto:anandvanbeachresort@gmail.com' target="blank">
            <MdEmail />
            <p>contactus@anandvanbeachresort.com</p>
        </a>
        <a href="tel:9769305050" target="blank">
            <AiFillPhone />
            <p>+91-9769305050</p>
        </a>
        <h2>Social Media</h2>
        <div className='social'>
        <a href='https://www.facebook.com/profile.php?id=100087098630049&mibextid=ZbWKwL' target="blank"><BsFacebook /></a>
        <a href='https://instagram.com/anandvanbeachresort?igshid=ZDdkNTZiNTM=' target="blank"><AiFillInstagram /></a>
        <a href='https://wa.me/message/3KFOG3ZBTAYIG1' target="blank"><RiWhatsappFill /></a>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Footer
