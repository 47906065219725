import React from 'react'
import "../styles/amenities.scss"
import {AiOutlineWifi, AiFillCar} from "react-icons/ai"
import { Ri24HoursFill } from 'react-icons/ri'
import { GiPowerGenerator, GiCctvCamera, GiHotSurface } from 'react-icons/gi'
import { FaTv } from 'react-icons/fa'
import {TbAirConditioning} from "react-icons/tb"
// import {CiParking1} from "react-icons/ci"

const Amenities = () => {

    const amenitiesData = [
        {
            icon: <AiOutlineWifi />,
            title: "Free Wifi"
        },
        {
            icon: <Ri24HoursFill />,
            title: "24 Hours Open"
        },
        {
            icon: <GiPowerGenerator />,
            title: "Power Backup"
        },
        {
            icon: <AiFillCar />,
            title: "Free Parking"
        },
        {
            icon: <GiCctvCamera />,
            title: "CCTV Camera"
        },
        {
            icon: <GiHotSurface />,
            title: "Geyser"
        },
        {
            icon: <FaTv />,
            title: "TV"
        },
        {
            icon: <TbAirConditioning />,
            title: "AC"
        }
    ]

  return (
    <div id='amenities'>
        <h2>Our Amenities</h2>
        <main>
            {amenitiesData.map((data)=>(
                <div className='card' key={data.title}>
                    {data.icon}
                    <p>{data.title}</p>
                </div>
            ))}
        </main>
    </div>
  )
}

export default Amenities
