import React from 'react'
import "../styles/services.scss"
import { MdHotel } from 'react-icons/md'
import { GiRollingDices, GiWoodenChair } from 'react-icons/gi'

const Services = () => {
  return (
    <div id="services">
      <h2>Services</h2>
      <main>
        <div className='card'>
            <MdHotel />
            <h4>Accomodation</h4>
            <p>We try to provide privacy and peace with the maximum comfort and safety that meets different necessities of our Guests for the duration of their Stay at our hotel.</p>
        </div>
        <div className='card'>
            <GiWoodenChair />
            <h4>Sitout & Terrace</h4>
            <p>We provide Dining as well as a place to just sit and Chillout with your family and have some quality time.</p>
        </div>
        <div className='card'>
            <GiRollingDices />
            <h4>Indoor Games</h4>
            <p>We provide Games such as Cards, Carrom etc. that you can enjoy with your Buddies or family and enjoy you stay at our Hotel.</p>
        </div>
      </main>
    </div>
  )
}

export default Services
