import React from 'react'

const Map = () => {
  return (
    <div style={{height:"100%", width:"100%"}}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.113367585932!2d72.9104579496869!3d18.613969987292645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be871f8f85c03f1%3A0xb64b4be10b7edc5e!2sAnandvan%20Beach%20Resort!5e0!3m2!1sen!2sin!4v1679122301235!5m2!1sen!2sin" title='map' style={{border:0, width:"100%", height:"100%"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  )
}

export default Map
