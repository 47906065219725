import React from 'react'
import "../styles/roomssuits.scss"
import deluxeking from "../assets/deluxeking.jpg"
import family from "../assets/family.jpg"
import king from "../assets/king.jpg"
import standard from "../assets/standard.jpeg"

const RoomsSuits = () => {

  const roomData =[
    {
      img: family,
      title: "Deluxe Family Room",
      describtion: "This Deluxe Family Room comes with a double bed and two single beds along with a connected Room which has a King Sized Bed. This room is ideal for a family with kids. The room is well lit with bright lighting and windows. An attached bathroom with basic amenities and free Toiletries. This air-conditioned room features a flat-screen Android TV and free WiFi is an added facility.",
      occupancy: "6"
    },
    {
      img: deluxeking,
      title: "Deluxe King Room",
      describtion: "This Deluxe King Room comes with a King size bed. A room which is ideal for Small families or Couples. The room is well lit with bright lighting and windows. An attached bathroom with basic amenities and free Toiletries. The private bathroom includes hot/cold shower facilities and free toiletries. This air-conditioned room features a flat-screen Android TV and free WiFi is an added facility",
      occupancy: "3"
    },
    {
      img: king,
      title: "King Room",
      describtion: "This King Room comes with a King size bed. A room which is ideal for Small families or Couples. The room is well lit with bright lighting and windows. An attached bathroom with basic amenities and free Toiletries. The private bathroom includes hot/cold shower facilities and free toiletries. This room features a flat-screen Android TV and free WiFi is an added facility.",
      occupancy: "3"
    },
    {
      img: standard,
      title: "Standard Room",
      describtion: "This standards Rooms are comfortably furnished with a queen sized bed and free Wi-Fi. A room which is idea for Couples or 2 guests. The room is well lit with bright lighting and windows. An attached bathroom with basic amenities and free toiletries. The private bathroom includes hot/cold Shower facilities.",
      occupancy: "2"
    },
  ]
  return (
    <div id="roomsandsuits">
      <h2>Rooms & Suits</h2>
      <main>
        {roomData.map((data) => <Card key={data.title} img={data.img} describtion={data.describtion} title={data.title} occupancy={data.occupancy} />)}
      </main>
    </div>
  )
}

const Card =({img, title, describtion, occupancy}) =>{
  return(
    <div className='card'>
      <img src={img} alt={title} />
      <div className='roomcontent'>
        <h4>{title}</h4>
        <p>{describtion}</p>
        <p className='occupancy'>Occupancy: {occupancy}</p>
        <a className='mmt' href="https://www.makemytrip.com/hotels/anandvan_beach_resort_alibaug-details-alibaug.html" target="blank">Book Now</a>
      </div>
    </div>
  )
}

export default RoomsSuits
