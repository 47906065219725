export const TestimonialData = [ 
    {
        id:"i1",
        name: "Dinesh Sonawane",
        rating: 5,
        message: "Extremely satisfied with stay and worth it. Rooms are well maintained, clean and hygienic. We stayed with our children and they took care of very small things of ours and demands. Best in hospitality and food, they offered us amazing and fresh sea food. The owners of property are personally involved to take care of your itinerary and stay. Staff is properly attentive, polite and nice."
    },
    {
        id: "i2",
        name: "Pragati",
        rating: 5,
        message: "Nice property. Room was clean, fresh & well organised. Seperate space for dinner & drinks, staff was kind and prompt."
    },
    {
        id: "i3",
        name: "Kiran Phadnis",
        rating: 5,
        message: "Amazing place for a family picnic to Nagaon Beach, Alibaug. Very clean and well maintained Rooms with clean Bathrooms. They have open Hall on 2nd floor for get together where you can chill out. But nothing beats their Sea-Food. It’s home cooked by family’s elder women. Variety of Fish Fry and Curry rice. Amazing taste."
    },
    {
        id: "i4",
        name: "Adarsh",
        rating: 5,
        message: "The best trip ever. The staff were the best and provided us with all the facilities. The rooms were spacious and clean. 10/10 recommended for everyone to visit here."
    },
];

