import React from 'react'
import "../styles/places.scss"
import NagaonBeach from "../assets/NagaonBeach.jpeg"
import KulabaKilla from "../assets/KulabaKilla.jpg"
import Revdanda_Fort from "../assets/Revdanda_Fort.jpeg"
import VarsoliBeach from "../assets/VarsoliBeach.jpeg"
import {AiFillStar} from "react-icons/ai"

const swiperData = [
    {
        id: 1,
        img: NagaonBeach,
        name: "Nagaon Beach",
        rating: 4
    },
    {
        id: 2,
        img: KulabaKilla,
        name: "Kulaba Killa",
        rating: 4.2
    },
    {
        id: 3,
        img: Revdanda_Fort,
        name: "Revdanda Fort",
        rating: 4.4
    },
    {
        id: 4,
        img: VarsoliBeach,
        name: "Varsoli Beach",
        rating: 4.3
    },
]

const Places = () => {
  return (
    <div id="places">
        <h2>Nearby Places</h2>
        <div className='swiper'>
            {swiperData.map((data)=>
            <div className='swiper-slide' key={data.id}>
                <img src={data.img} alt={data.name} />
                <div className='slidecontent'>
                    <p>{data.name}</p>
                    <div>
                        <AiFillStar />
                        <p>{data.rating}</p>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
  )
}

export default Places
