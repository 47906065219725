import React, { useRef } from 'react'
import "../styles/gallery.scss"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useWindowDimensions from './hooks/useWindowDimensions';
import {AiOutlineRight, AiOutlineLeft} from "react-icons/ai"
// import { motion } from 'framer-motion';

const Gallery = () => {
    function importAll(r) {
        return r.keys().map(r);
    }

    const modalFocus = useRef(null)
    const modalImgFocus = useRef(null)

    const handleClick =(e) =>{
        modalFocus.current.className+="open"
        modalImgFocus.current.className+="open"
        modalImgFocus.current.src = e.target.src
        document.body.style.overflow="hidden"
    }

    const modalClick =(e) => {
        if(e.target.className === "modalopen"){
            modalFocus.current.className = modalFocus.current.className.slice(0,-4);
            modalImgFocus.current.className = modalImgFocus.current.className.slice(0,-4);
            document.body.style.overflow="unset"
        }
    }
      
    const filenames = importAll(require.context('../assets/anandvan/', false, /\.(png|jpe?g|svg)$/));

    const photos = filenames.map(filename => (
        <div key={filename} className="imgdiv">
            <img src={filename} alt={filename} className="imgimg" onClick={handleClick} />
        </div>
    ))

    const { width } = useWindowDimensions();
    
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: width/330,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: width/330,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: width/330,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: width/330,
        }
      }

    return(
       <div id="gallery">
        <h2>Gallery</h2>
        <Carousel responsive={responsive} draggable swipeable customRightArrow={<CustomRightArrow />} customLeftArrow={<CustomLeftArrow />} autoPlay focusOnSelect transitionDuration={1000} centerMode infinite>
        {photos}
        </Carousel>
        <div className='modal' ref={modalFocus} onClick={modalClick}>
            <img src="" alt="" ref={modalImgFocus} className='modalimg' />
        </div>
        </div>
    )
}

const CustomLeftArrow = ({ onClick, ...rest }) => {
    return <button onClick={() => onClick()} className="arrowleft" ><AiOutlineLeft /></button>;
};

const CustomRightArrow = ({ onClick, ...rest }) => {
    return <button onClick={() => onClick()} className="arrowright" ><AiOutlineRight /></button>;
};

export default Gallery
