import React from 'react'
import "../styles/aboutus.scss"
import img1 from "../assets/abtimg11.jpeg"
import img2 from "../assets/abtimg2.jpg"

const AboutUs = () => {

  return (
    <div id="aboutus">
      <div className='aboutImages'>
        <p>
        <img src={img1} alt="img1" className='img1' />
        <img src={img2} alt="img2" className='img2' />
        </p>
      </div>
      <div className='aboutContent'>
        <h2>Welcome to Anandvan</h2>
        <p>Ideal getaway for those who love the sea and away from the busy areas of metropolitan hues and cries, Anandvan resort serves as a great weekend getaway for beach lovers. Couples, families, and picnickers come here to enjoy their evenings.</p>
        <p>It all started with an open land near Nagaon Beach, an abandoned fort amid the jungle with black sand that attracted tourist to ensnare the senses and relaxes your soul to the core! Anandvan is ready to truly lock the feel of a rural village stretching along with the beach and meeting Arabian sea waves. Located conveniently near Mumbai and Pune with clean water and excellent accommodation with a perfect blend of a romantic getaway, family outings, birthday events, weddings, corporate event gatherings and much more within the warmth of personal hospitality to give you an experience that you never had before. Our resort is situated 20-minute car ride away from Alibaug and 2km from Nagaon Beach. We enjoy the intersection of being near the city yet far away from hustle and bustle of the city.</p>
        <h2>Important Notice</h2>
        <ul>
            <li>Check-in time is 12:00 PM and check-out time is 11:00 AM noon. Subject to availability, early check-in and late check-out will be considered.</li>
            <li>Extra Person charges – Rs. 350 per person.</li>
            <li>100% advance Payment deposit at the time of Check in.</li>
            <li>It is Mandatory for all guests to share their ID cards. Foreign Nationals are required to present their passport and valid visa.</li>
            <li>Smoking, Dining and Drinking is prohibited inside the Room but, we have a Designated area(Terrace) for the same.</li>
            <li>Gambling, Prostitution, contraband goods, drugs, etc – are all strictly prohibited within the hotel premises.</li>
            <li>We do not allow visitors entry to guest room.</li>
            <li>Children up-to 10 Years of age can stay free above 10 years will be charged as per extra adult rate.</li>
        </ul>
      </div>
    </div>
  )
}

export default AboutUs
